<template>
  <div class="p-4">
    <div class="grid divide-y border rounded">
      <button
        v-for="(option, index) in options"
        :key="`${option.label}-${index}`"
        class="flex p-3 text-center items-center hover:bg-gray-100 dark:hover:bg-gray-800"
        @click="$emit('update', option.value)"
      >
        <slot name="icon" :active="active" :option="option">
          <Icon
            class="w-8 h-8"
            :class="
              active(option)
                ? 'text-primary-500 dark:text-primary-400'
                : 'text-gray-400 dark:text-primary-400'
            "
            :name="option.icon"
          />
        </slot>
        <span
          class="block ms-3 text-sm"
          :class="
            active(option)
              ? 'text-primary-500 dark:text-primary-400'
              : 'text-black dark:text-gray-300'
          "
        >
          {{ $t(option.label) }} {{ additionalLabel }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { isEqual } from "lodash-es";
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: [String, Number, Object],
    additionalLabel: {
      type: String,
      default: "",
    },
  },

  methods: {
    active(option) {
      return isEqual(option.value, this.value);
    },
  },
};
</script>
