export default [
  {
    value: "list",
    icon: "IcRoundViewStream",
    label: "list_view",
  },
  {
    value: "compact",
    icon: "IcRoundViewList",
    label: "compact_view",
  },
  {
    value: "grid",
    icon: "IcRoundGridView",
    label: "grid_view",
  },
];
